import axios from 'axios';
import { put } from 'redux-saga/effects';

import callApi from '../../utils/callApi';
import history from '../../utils/history';
import getSiteEndpoint from '../../utils/getSiteEndpoint';

import userTypes from './userTypes';

export function* getUsers(params) {
  let defaultParams = {}

  if (params)
    params = params.payload || {};
  else
    params = {};

  params = {
    ...defaultParams,
    ...params
  };
  
  let query = new URLSearchParams();
  Object.keys(params).map(key => {
    console.log('params=========>>>>',params);
    query.set(key, params[key]);
    return null;
  });

  if(!query.has('per_page')) {
    query.set('per_page', 9999)
  }

  yield callApi({
    method: "get",
    url: getSiteEndpoint() + "/users?" + query.toString(),
    type: "GET_USERS",
    useFullResponse: false,
    dataProp: "users",
    errorMessage: "There was an error while getting users.",
  });
}

export function* getUser(action) {

  if (!action.payload.uuid) put({ type: userTypes.GET_USER_FAILURE });

  yield callApi({
    method: "get",
    url: getSiteEndpoint() + "/users/" + action.payload.uuid,
    type: "GET_USER",
    useFullResponse: false,
    dataProp: "user",
    errorMessage: "There was an error while getting users.",
  });
}

export function* updateUser(action) {
  if (!action.payload.id) put({ type: userTypes.UPDATE_USER_FAILURE })

  yield callApi({
    method: "put",
    url: getSiteEndpoint() + "/users/" + action.payload.id,
    type: "UPDATE_USER",
    useFullResponse: false,
    body: action.payload,
    dataProp: "user",
    errorMessage: "There was an error while updating user.",
    successMessage: "User was successfully updated",
    onSuccess: () => history.push("/a/postmortems"),
  });
}

export function* updatePassword({ payload }) {
  try {
    const token = localStorage.getItem('token');
    const res = yield axios.post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/update-password`,
      { token, password: payload }
    );

    if (res.status === 200) {
      history.push("/a/postmortems");
    }

    yield put({
      type: userTypes.UPDATE_PASSWORD_SUCCESS,
      metaData: {
        toastErorr: false,
        toastMessage: 'Your password has been successfully updated',
      },
    });
  } catch (error) {
    yield put({
      type: userTypes.UPDATE_PASSWORD_FAILURE,
      payload: error.response.data,
      metaData: {
        toastError: true,
        toastMessage: error.response.data.message,
      },
    });
  }
}

